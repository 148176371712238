<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4">
        <div class="footer-col">
          <div class="footer-logo text-left mb-5">
            <img src="{{doaminName+schoolData.logo}}" width="150">
          </div>
          <ul class="social-list ">
            <li><a [href]="schoolData.facebook" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
            <br>
            <li><a [href]="schoolData.twitter" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <li><a [href]="schoolData.youtube" target="_blank"><i class="fa fa-youtube"></i></a></li>
            <li><a [href]="schoolData.instgram" target="_blank"><i class="fa fa-instagram"></i></a></li> 
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="footer-col">
          <h5 class="footer-title">Contact Us</h5>
          <ul class="contact-list">
            <li>
              <a href="tel: +011111111">
                <i class="fa fa-phone"></i>
                <span>{{schoolData.phone}}</span>
              </a>
            </li>
            <li>
              <a href="mailto: support@school.com">
                <i class="fa fa-envelope"></i>
                <span> {{schoolData.mail}}</span>
              </a>
            </li>
            <li>
              <p class="mb-0">
                <i class="fa fa-map"></i>
                <span>{{schoolData.address}}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="footer-col">
          <h5 class="footer-title">{{'UsefulLinks'|translate}}</h5>
          <ul class="links-list">
            <li><a routerLink="/">{{'home'|translate}}</a></li>
            <li><a routerLink="/about">{{'about'|translate}}</a></li>
            <li><a routerLink="/news">{{'news'|translate}}</a></li>
            <li><a routerLink="/apply">{{'applynow'|translate}}</a></li>
            <li><a routerLink="/jobs">{{'Jobs'|translate}}</a></li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</footer>
